import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)

function openModal( target ) {
  $.magnificPopup.open({
    items: {
      src: target,
      type: 'inline'
    },
    // callbacks: {
    //   close: function(){
    //     const url = new URL( window.location )
    //     url.hash = ''
    //     history.pushState( {}, '', url )
    //   }
    // },
    focus: "input[type=text]",
    closeBtnInside: true,
    autoFocusLast: true,
  });
}

$doc.ready( ()=>{
  // add wrapper to inputs to allow notch
  $('.form-group input').wrap( '<div class="input-wrapper"></div>' );
  $('.frm_form_fields input[type="text"]').wrap( '<div class="input-wrapper"></div>' );
  //$('.footer__newsletter input[type="email"]').wrap( '<div class="input-wrapper"></div>' );

  $('.footer__newsletter label').addClass('u-sr-only');

  // add icon to submit button
  const icon = '<svg class="button-arrow" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"><circle cx="20" cy="20" r="20" fill="#FFC000"/><path d="M29.7971 20.7071C30.1877 20.3166 30.1877 19.6834 29.7971 19.2929L23.4332 12.9289C23.0426 12.5384 22.4095 12.5384 22.019 12.9289C21.6284 13.3195 21.6284 13.9526 22.019 14.3431L27.6758 20L22.019 25.6569C21.6284 26.0474 21.6284 26.6805 22.019 27.0711C22.4095 27.4616 23.0426 27.4616 23.4332 27.0711L29.7971 20.7071ZM10.9082 21L29.09 21L29.09 19L10.9082 19L10.9082 21Z" fill="#013766"/></svg>';
  const newsletterIcon = '<svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0554 10.0554L1 1" stroke="#FFC000"/><path d="M10.0554 9.29311L1.00005 18.3485" stroke="#FFC000"/></svg>';
  $('.form-group button[type="submit"]').prepend(icon);
  $('.frm_forms .frm_button_submit').prepend(icon);
  $('.footer__newsletter .fb-button').find('svg').remove();
  $('.footer__newsletter .fb-button button').wrapInner('<span class="u-sr-only"></span>').prepend(newsletterIcon);

  $doc.on('click', '.js-open-modal', e => {openModal( e.target.hash )} );

  // set hidden field to be the team member's modal contact form that was opened.
  let popupWrappers = document.querySelectorAll('.mfp-wrapper');
  popupWrappers.forEach((popup) => {
    let teamMember = popup.dataset.teammember;
    let hiddenField = popup.querySelector('input[name="item_meta[25]"');
    if(hiddenField) hiddenField.value = teamMember;
  });

})
