import $ from 'jquery'

const $doc = $(document);
const loader = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="110px" height="110px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<circle cx="50" cy="50" fill="none" stroke="#013766" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.0204081632653061s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
</circle>`;

const contentWrapper = $('.projects__wrapper');
const filterForm = $('.projects-filters__search-form');

function filterProjects(page) {
  const focusAreaValue = $('#focus-area option:selected')[0].value;
  const clientTypeValue = $('#client-type option:selected')[0].value;
  const searchTerm = filterForm.find("#search").val();

  const data = {
    action : "projects_filter",
    focusArea : focusAreaValue,
    clientType : clientTypeValue,
    search : searchTerm,
    page : page
  }

  contentWrapper.empty();
  return $.ajax({
    url : php_vars.ajax_url,
    data : data,
    beforeSend: function () {
      contentWrapper.attr('aria-busy', 'true').append(loader)
    },
    success : function(response) {
      if (response == "" || response == null) {
        contentWrapper.attr('aria-busy', 'false').html('<p>No results found, please try a different combination of filters.</p>');
      } else {
        contentWrapper.attr('aria-busy', 'false').html(response);
      }
    },
    error: function () {
      contentWrapper.html('<p>Sorry, no results found.</p>');
    },
  });
}

function initPagination(e) {
  e.preventDefault();
  $('html, body').animate({
    scrollTop: contentWrapper.offset().top - 300
  }, 1000);
  let page = parseInt($(this).html());
  let currentPage = parseInt($('.page-numbers.current').html());
  if($(this).hasClass('next')) {
    page = currentPage + 1;
  }
  if($(this).hasClass('prev')) {
    page = currentPage - 1;
  }
  if($(this).hasClass('current')) {
    return;
  }
  filterProjects(page);
}

function init() {
  // if($('.js-reset-filters').length === 0) {
  //   $(this).remove();
  //   $('.projects-filters__wrapper').append('<button type="button" class="js-reset-filters" aria-label="Reset Filters">Reset</button>')
  // }
  filterProjects(1);
}

$doc.on('click', '.js-projects-pagination a', initPagination )
//$doc.on('change', '.js-projects-filter', init );
$doc.on('click', '.js-apply-filters', init );
$doc.on('click', '.js-reset-filters', function () {
  $(this).remove();
  $('.js-projects-filter').each(function(){
    $(this).val($('.js-projects-filter option:first').val());
  });
  filterProjects(1)
})
filterForm.submit(function(e){
  e.preventDefault();
  filterProjects(1)
});