import $ from 'jquery'

$(document).ready( initTestimonialsSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=testimonials', initTestimonialsSlider)
}

function initTestimonialsSlider() {

  $('.testimonials__wrapper').each(function(index, element) {
  
    const slider = $(element).find('.js-testimonials-slider');
    const counter = $(element).find('.js-testimonial-counter');
    const pause = $(element).find('.js-pause-testimonials');
    const play = $(element).find('.js-play-testimonials');

    if (slider.length) {

      slider.slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        dots: false,
        //infinite: false,
        autoplay: true,
        autoplaySpeed: 8000,
        nextArrow: '<button type="button" class="slick-next" aria-label="Next Slide"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="circle" d="M39.479 20C39.479 31.0457 30.6483 40 19.7551 40C8.86193 40 0.03125 31.0457 0.03125 20C0.03125 8.9543 8.86193 0 19.7551 0C30.6483 0 39.479 8.9543 39.479 20Z" fill="#FFC000"/><path d="M17.4573 11.124C17.1937 11.1241 16.9359 11.2023 16.7167 11.3488C16.4975 11.4953 16.3267 11.7035 16.2258 11.9471C16.1249 12.1907 16.0985 12.4588 16.1499 12.7174C16.2013 12.976 16.3283 13.2135 16.5147 13.4L23.1147 20L16.5147 26.6C16.2718 26.8515 16.1374 27.1883 16.1404 27.5379C16.1435 27.8875 16.2837 28.2219 16.5309 28.4691C16.7781 28.7163 17.1125 28.8565 17.4621 28.8596C17.8117 28.8626 18.1485 28.7282 18.4 28.4853L25.9427 20.9427C26.1926 20.6926 26.333 20.3536 26.333 20C26.333 19.6464 26.1926 19.3074 25.9427 19.0573L18.4 11.5147C18.15 11.2646 17.8109 11.1241 17.4573 11.124Z" fill="#013766"/></svg></button>',
        prevArrow: '<button type="button" class="slick-prev" aria-label="Previous Slide"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="circle" d="M39.479 20C39.479 31.0457 30.6483 40 19.7551 40C8.86193 40 0.03125 31.0457 0.03125 20C0.03125 8.9543 8.86193 0 19.7551 0C30.6483 0 39.479 8.9543 39.479 20Z" fill="#FFC000"/><path d="M22 11.124C22.2637 11.1241 22.5214 11.2023 22.7406 11.3488C22.9598 11.4953 23.1307 11.7035 23.2316 11.9471C23.3325 12.1907 23.3589 12.4588 23.3074 12.7174C23.256 12.976 23.1291 13.2135 22.9427 13.4L16.3427 20L22.9427 26.6C23.1855 26.8515 23.3199 27.1883 23.3169 27.5379C23.3139 27.8875 23.1736 28.2219 22.9264 28.4691C22.6792 28.7163 22.3448 28.8565 21.9952 28.8596C21.6456 28.8626 21.3088 28.7282 21.0573 28.4853L13.5147 20.9427C13.2647 20.6926 13.1243 20.3536 13.1243 20C13.1243 19.6464 13.2647 19.3074 13.5147 19.0573L21.0573 11.5147C21.3073 11.2646 21.6464 11.1241 22 11.124Z" fill="#013766"/></svg></button>',
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });

      slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
        counter.html(currentSlide+1);
      });

      pause.on('click', function() {
        slider.slick('slickPause')
        $(this).hide();
        play.show();
      });

      play.on('click', function() {
        slider.slick('slickPlay')
        $(this).hide();
        pause.show();
      });

    }

  });
}
