import $ from 'jquery'

const $doc = $(document)

function toggleAccordionItem() {
  let el = $(this);
  if ( el.parent().parent().hasClass('is-active') ) {
    el.attr('aria-expanded', 'false');
  } else {
    el.attr('aria-expanded', 'true');
  }
  el.parent().next().slideToggle('fast');
  el.parent().parent().toggleClass('is-active');
}

$doc.on('click', '.js-accordion-item button', toggleAccordionItem )
