let lottieBlock = document.querySelectorAll(".wp-block-acf-animation");

lottieBlock.forEach((lottie) => {
  let lottieAnimation = lottie.querySelector("#animationBlockLottie");
  let pauseButton = lottie.querySelector(".lottie__pause");
  let playButton = lottie.querySelector(".lottie__play");

  pauseButton.addEventListener("click", () => {
    lottieAnimation.pause();
    pauseButton.classList.add("paused");
  });

  playButton.addEventListener("click", () => {
    lottieAnimation.play();
    pauseButton.classList.remove("paused");
  });
});


