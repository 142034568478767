import $ from 'jquery'
import lottieWeb from 'lottie-web';

$(document).ready( initAnimationButton )

function initAnimationButton() {
  //const player = document.querySelector("#page-header__lottie-player");
  const container = $('#home-animation');
  const lottieFile = container.data('lottie');

  if (container && lottieFile) {

    var animationDetails = new XMLHttpRequest();
    var animationObj;
    //var animationUpdated;

    //animationDetails.open('GET', 'https://dunsky23.wpenginepowered.com/wp-content/themes/_dunsky/animations/dunsky_home_v14.json', true);
    animationDetails.open('GET', lottieFile, true); // I added the file dynamically, js fetches it from the data atribute of the container
    animationDetails.responseType = 'text';
    animationDetails.send(null);

    animationDetails.onload = function() {

      if (animationDetails.status === 200) {
        animationObj = JSON.parse(animationDetails.responseText);
        //console.log(animationObj);

        // overwrite urls within the lottie animation
        animationObj.assets[0].p = container.data('image1');
        animationObj.assets[1].p = container.data('image2');
        animationObj.assets[2].p = container.data('image3');
        animationObj.assets[3].p = container.data('image4');
        animationObj.assets[4].p = container.data('image5');
        animationObj.assets[5].p = container.data('image6');
        animationObj.assets[6].p = container.data('image7');
        animationObj.assets[7].p = container.data('image8');

        // test output
                  // for (i = 0; i <= 5; i++) {
        //     console.log(animationObj.assets[i].p);
          // }

        // convert JSON obj to string
        //animationUpdated = JSON.stringify(animationObj);

        // pass to player and load
        //document.getElementById("page-header__lottie-player").load(animationUpdated);

        const lottieanimation = lottieWeb.loadAnimation({
          container: container[0],
          //path: lottie,
          animationData: animationObj, //if we use lottie web we can work with the lottie as an object and paste de updated json here directly
          renderer: 'svg',
          autoplay: true,
          name: "Home Animation",
          loop: false,
          speed: 1,
          assetsPath: 'https://dunsky23.wpenginepowered.com/wp-content/uploads/'
        });

        // pause on last frame
        // lottieanimation.addEventListener("enterFrame", function (animation) {
        //   if (animation.currentTime > (lottieanimation.totalFrames - 1)) {
        //     lottieanimation.pause();
        //   }
        // });

        // updated play pause functionality to work with lottie web
        let playsymbol = document.querySelector("#playsymbol");
        let pausesymbol = document.querySelector("#pausesymbol");

        pausesymbol.onclick = function () {
          lottieanimation.pause()
          playsymbol.classList.add('visible');
          pausesymbol.classList.remove('visible');
        };

        playsymbol.onclick = function () {
          lottieanimation.play()
          playsymbol.classList.remove('visible');
          pausesymbol.classList.add('visible');
        };
      }
    };

  }
}