import $ from 'jquery'
import 'slick-carousel'
import { Fancybox } from "@fancyapps/ui"

Fancybox.bind("[data-fancybox]")


$(document).ready( initGallerySlider )
if (window.acf) {
    window.acf.addAction('render_block_preview/type=image_gallery', initGallerySlider)
}

function initGallerySlider() {
  if ($('.js-gallery-slider').length) {
    $('.js-gallery-slider').slick({
      arrows: true,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      prevArrow: `<button type="button" class="slick-prev" aria-label="Previous Slide"><svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <ellipse cx="21.6616" cy="21.9658" rx="21.6626" ry="21.9658" transform="rotate(-180 21.6616 21.9658)" fill="#FFC000"/>
      <path d="M10.953 21.2587C10.5625 21.6492 10.5625 22.2824 10.953 22.6729L17.317 29.0369C17.7075 29.4274 18.3407 29.4274 18.7312 29.0369C19.1217 28.6464 19.1217 28.0132 18.7312 27.6227L13.0744 21.9658L18.7312 16.309C19.1217 15.9184 19.1217 15.2853 18.7312 14.8948C18.3407 14.5042 17.7075 14.5042 17.317 14.8948L10.953 21.2587ZM31.6602 20.9658L11.6602 20.9658L11.6602 22.9658L31.6602 22.9658L31.6602 20.9658Z" fill="#013766"/>
      </svg></button>`,
      nextArrow: `<button type="button" class="slick-next" aria-label="Next Slide"><svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <ellipse cx="21.6626" cy="21.9658" rx="21.6626" ry="21.9658" fill="#FFC000"/>
      <path d="M32.3712 22.6729C32.7617 22.2824 32.7617 21.6492 32.3712 21.2587L26.0072 14.8948C25.6167 14.5042 24.9835 14.5042 24.593 14.8948C24.2025 15.2853 24.2025 15.9184 24.593 16.309L30.2498 21.9658L24.593 27.6227C24.2025 28.0132 24.2025 28.6464 24.593 29.0369C24.9835 29.4274 25.6167 29.4274 26.0072 29.0369L32.3712 22.6729ZM11.6641 22.9658L31.6641 22.9658L31.6641 20.9658L11.6641 20.9658L11.6641 22.9658Z" fill="#013766"/>
      </svg></button>`,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        ]
    });
  }
}