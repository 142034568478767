import $ from 'jquery'
import 'slick-carousel'

const $doc = $(document)

function pasteBios(n) {
  const count = $('.team__member').length;
  $('.team__member:nth-child('+n+'n)').after('<div class="team__bio-container grid__column is-active"></div>');
    if( !count % 2 === 0 ) {
      $('.team__member:last-child').after('<div class="team__bio-container grid__column is-active"></div>');
    }
}

function addTeamBios() {
  if ($(window).width() < 510) {
    return
  } else if ($(window).width() < 772) {
    pasteBios(2);
  } else if ($(window).width() < 960) {
    pasteBios(3);
  } else {
    pasteBios(4);
  }
}

let width = $(window).width(); 
$(window).on('resize', function() {
  if ($(window).width() == width) return;
  width = $(window).width();
  $('.team__bio-container').remove();
  $('.team__member').removeClass('is-active');
  $('.team__bio-popup').slideUp();
  addTeamBios();
});

function openTeamBio(e) {
  const teamMember = $(this).parent().parent('.team__member');
  const button = teamMember.find('.team__button');
  const memberBio = teamMember.find('.team__bio-popup');
  const bioContainer = teamMember.nextAll('.team__bio-container:first');
  const isDesktop = $(window).width() >= 570;
  const isNotActive = !teamMember.hasClass('is-active');

  $('.team__button').not(button).attr('aria-expanded', 'false');
  if ( teamMember.hasClass('is-active') ) {
    button.attr('aria-expanded', 'false');
  } else {
    button.attr('aria-expanded', 'true');
  }

  $('.team__bio-container').each(function() {
    if ( $(this).hasClass('is-open') && $(this)[0] !== bioContainer[0] ) {
      $(this).hide()
      //$(this).removeClass('is-open')
    }
  });

  !isNotActive && bioContainer.slideUp();
  $('.team__bio-popup').slideUp();
  $('.team__member').removeClass('is-active');
  isNotActive && teamMember.addClass('is-active');
  
  if ( !isDesktop ) {
    isNotActive && memberBio.slideDown({
      duration: 200,
      complete: function(){
        $('html, body').animate({
          scrollTop: teamMember.offset().top
        }, 500);
      }
    });
  } else {
    isNotActive && bioContainer.slideDown();    
    bioContainer.hasClass('is-open') ? bioContainer.removeClass('is-open') : bioContainer.addClass('is-open');
    bioContainer.html( memberBio.html() );
  }
}

function closeTeamBio() {
  $('.team__bio-container').slideUp();
  $('.team__member').removeClass('is-active');
}

$(document).ready( addTeamBios )
$doc.on('click', '.js-open-team-bio', openTeamBio )
$doc.on('click', '.js-close-team-bio', closeTeamBio )
$(document).ready( function(){
  $('.team__bio-popup').on('click',function(e){
    e.preventDefault();
 });
});
