import $ from 'jquery';
const $doc = $(document);
const loader = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="110px" height="110px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<circle cx="50" cy="50" fill="none" stroke="#013766" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.0204081632653061s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
</circle>`;

$doc.on('click', '.js-clients-pagination a', initPagination )
$doc.on('click', '.js-clients .js-clients-item', initTabs )
const tabContent = $('.clients__wrapper');

function initPagination(e) {
  e.preventDefault();
  let page = parseInt($(this).html());
  let currentPage = parseInt($('.page-numbers.current').html());
  if($(this).hasClass('next')) {
    page = currentPage + 1;
  }
  if($(this).hasClass('prev')) {
    page = currentPage - 1;
  }
  if($(this).hasClass('current')) {
    return;
  }
  toggleTabs(e,page);
}

function initTabs(e) {
  e.preventDefault();
  if(!$(this).hasClass('active')) {
    $(this).addClass('active').attr('aria-selected', 'true').removeAttr('tabindex')
    $('.js-clients-item').not(this).removeClass('active').attr('aria-selected', 'false').attr('tabindex', '-1');
  }
  toggleTabs(e,1);
}

function toggleTabs(e,page) {
  e.preventDefault();
  const category = $('.js-clients-item.active').attr('data-cat');
  const data = {
    action : "toggle_tabs",
    cat : category,
    page : page,
  }

  tabContent.empty();
  return $.ajax({
    url : php_vars.ajax_url,
    data : data,
    beforeSend: function () {
      tabContent.attr('aria-busy', 'true').append(loader)
    },
    success : function(response) {
      tabContent.attr('aria-busy', 'false').html(response);
    }
  });
}

$( '.js-clients' ).each(function() {
  var index = 0;
  var $tabs = $(this).find('.js-clients-item');

  $tabs.bind(
  {
    keydown: function(ev){
      var LEFT_ARROW = 37;
      var UP_ARROW = 38;
      var RIGHT_ARROW = 39;
      var DOWN_ARROW = 40;

      var k = ev.which || ev.keyCode;

      if (k >= LEFT_ARROW && k <= DOWN_ARROW){

        if (k == LEFT_ARROW || k == UP_ARROW){
          if (index > 0) {
            index--;
          }
          else {
            index = $tabs.length - 1;
          }
        }

        else if (k == RIGHT_ARROW || k == DOWN_ARROW){
          if (index < ($tabs.length - 1)){
            index++;
          }
          else {
            index = 0;
          }
        }

        $($tabs.get(index)).click();
        ev.preventDefault();
      }
    },

    click: function(ev){
      index = $.inArray(this, $tabs.get());
      //initTabs(ev);
      ev.preventDefault();
    }
  });
});